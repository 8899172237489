import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'

const useStyle = makeStyles({
  column: (maxLine) => ({
    maxWidth: '100%',
    width: 'max-content',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
    // display: '-webkit-box',
    WebkitLineClamp: maxLine,
    WebkitBoxOrient: 'vertical',
  }),
  tooltip: {
    fontSize: '13px',
    width: 'max-content',
    maxWidth: 'none',
  },
}, { name: 'BO-EllipsisColumn' })

function EllipsisColumn ({ maxLine = 3, children, isTooltip = false }) {
  const ref = useRef(null)
  const classes = useStyle(maxLine)
  const [showTooltip, setShowTooltip] = useState(true)

  useEffect(() => {
    // 總高度 > 顯示高度才顯示 tooltip
    if (ref.current) {
      if (isTooltip) {
        setShowTooltip(true)
      } else {
        const scrollHeight = ref.current.scrollHeight
        const offsetHeight = ref.current.offsetHeight
        setShowTooltip(scrollHeight > offsetHeight)
      }
    }
  }, [children, isTooltip])

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={showTooltip ? children : ''}
      interactive // 游標移至 tooltip 上不會消失
    >
      <div ref={ref} className={classes.column}>
        {children}
      </div>
    </Tooltip>
  )
}

EllipsisColumn.propTypes = {
  // 超過多少行數才顯示省略號
  maxLine: PropTypes.number,
}

export default EllipsisColumn
