// 注單查詢
import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment'

import a from '../../actions'
import {
  getReportByFilter,
  getLanguage,
  getTimeZone,
  getIsFetchingByFilter,
  getLiveTableTypeOption,
  getLiveTableIdOption,
} from '../../reducers'
import { qsParse, formatDate, formatMoney } from '../../helpers'
import language from '../../locale/livePlayerOrder'
import { getSavedState } from '../../helpers/storage'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import ReportSummaryCard from '../../components/ReportSummaryCard'
import GridItemFull from '../../components/grids/GridItemFull'
import PlayerOrderForAdminForm from './form'
import DataTable from '../../components/tables/DataTable'
import EllipsisColumn from '../../components/tables/EllipsisColumn'
import MoreButton from '../../components/MoreButton'

const styles = theme => ({
  true: {
    color: green[500],
    fontWeight: 900,
  },
  false: {
    color: grey[500],
    fontWeight: 900,
  },
})

class LivePlayerOrderForAdmin extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rowsPerPage: 30,
      columns: [],
      payload: null,
      reportPagination: null,
      formatReports: [],
      formatSummaryReports: [],
      needFetchOnMount: false,
      isSearched: false,
      isDownloaded: false,
      tableIdOptions: [],
    }
  }

  componentDidMount () {
    const { i18n, fetchTableInfo } = this.props

    i18n.addResourceBundle('en', 'livePlayerOrder', language.en)
    i18n.addResourceBundle('cn', 'livePlayerOrder', language.cn)

    fetchTableInfo()
  }

  componentDidUpdate (props, prevState) {
    const {
      reports = {},
      summaryReports,
      language,
      timezone,
      tableIdAllOptions,
      fetchTableInfo,
    } = this.props

    // 切換時區要自動重載資料，並提示使用者
    // 判斷 component 若為需預先載入資料（needFetchOnMount）或者有搜尋過(prevState.isSearched)才能重撈。
    // 白話意思是使用者必須先點搜尋一次，或者這個 Component 在 Mount 的時候就會載入資料的類型，這時切換時區才會重新撈取資料）
    if ((prevState.needFetchOnMount || prevState.isSearched) && props.timezone !== timezone) {
      this.handleChangeTimeZone(timezone)
    }

    if (props.reports !== reports) {
      this.setFormatReports(reports.data)
      this.setReportPagination(reports.pagination)
    }

    // 若已經搜尋過，切換語系時需重撈報表（「遊戲名稱」語系由 bo 給）
    if (prevState.payload && props.language !== language) {
      this.fetchReport(prevState.payload)
    }

    if (props.language !== language || props.timezone !== timezone) {
      this.setColumns()
      fetchTableInfo()
    }

    if (props.summaryReports !== summaryReports) {
      this.setFormatSummaryReports(summaryReports)
    }

    if (props.tableIdAllOptions !== tableIdAllOptions) {
      this.setTableIdOptions()
    }
  }

  setTableIdOptions = (selectedTableType = []) => {
    const { tableIdAllOptions } = this.props

    if (selectedTableType.length <= 0) {
      this.setState({ tableIdOptions: tableIdAllOptions })
      return
    }

    const tableIdOptions = tableIdAllOptions.filter(({ tabletype }) =>
      selectedTableType.find(({ value }) => value === tabletype),
    )
    this.setState({ tableIdOptions })
  }

  onTableTypeChange = option => {
    this.setTableIdOptions(option)
  }

  setColumns = () => {
    const { t } = this.props

    this.setState({
      columns: [
        // 代理帳號
        {
          name: t('agentAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        // 玩家帳號
        {
          name: t('playerAcc'),
          options: {
            filter: false,
            sort: false,
          },
        },
        // 單號
        {
          name: t('roundid'),
          options: {
            filter: false,
            sort: false,
          },
        },
        // 真人遊戲名稱
        {
          name: t('tableType'),
          options: {
            filter: false,
            sort: false,
          },
        },
        // 桌號
        {
          name: t('tableId'),
          options: {
            filter: false,
            sort: false,
          },
        },
        // 下注玩法
        {
          name: t('bettype'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => <EllipsisColumn>{value}</EllipsisColumn>,
          },
        },
        // 投注額
        {
          name: t('bet'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        // 贏分
        {
          name: t('win'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        // 有效投注額
        {
          name: t('validbet'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: value => formatMoney(value),
          },
        },
        // 幣別
        {
          name: t('currency'),
          options: {
            filter: false,
            sort: false,
          },
        },
        // 遊戲結果
        {
          name: t('gameresult'),
          options: {
            filter: false,
            sort: false,
            hint: t('gameresultHint'),
            customBodyRender: value => <EllipsisColumn isTooltip>
              {value}
            </EllipsisColumn>,
          },
        },
        // 下注時間
        {
          name: t('bettime'),
          options: {
            filter: false,
            sort: false,
            // 把 this.props 拿掉會有 scope 問題喔
            customBodyRender: value => moment(value * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        // 結算時間
        {
          name: t('finaltime'),
          options: {
            filter: false,
            sort: false,
            // 把 this.props 拿掉會有 scope 問題喔
            customBodyRender: value => moment(value * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          name: t('betIP'),
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: t('more'),
          options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRender: value => {
              return (
                <MoreButton
                  items={[
                    {
                      label: t('gameDetail'),
                      onClick: () => {
                        this.handleGameLink({
                          host: '0', // 預設中國線路
                          ...value,
                        })
                      },
                    },
                  ]}
                />
              )
            },
          },
        },
      ],
    })
  };

  setReportPagination = (data = {}) => {
    this.setState({
      reportPagination: data,
    })
  };

  setFormatReports = (data = []) => {
    this.setState({
      formatReports: data.map(item => [
        item.parentaccount,
        item.account,
        item.roundid,
        item.tabletype,
        item.tableid,
        item.bettype.join(', '),
        item.bet,
        item.win,
        item.validbet,
        item.currency,
        item.gameresult,
        item.bettime,
        item.finaltime,
        item.da[0].value,
        { roundid: item.roundid, gamehall: item.gamehall },
      ]),
    })
  };

  setFormatSummaryReports = data => {
    const isNotEmpty = Object.keys(data).length !== 0

    const keys = [
      'totallivebets',
      'totallivewins',
      'totalvalidbets',
      'parentincome',
    ]
    this.setState({
      formatSummaryReports:
        data && isNotEmpty
          ? [
            {
              name: 'LIVE',
              data: keys.map((key) => ({
                key,
                value: data.live[key],
              })),
            },
          ]
          : [],
    })
  };

  handleGameLink = async values => {
    return this.props.fetchGameDetailLink(values, { gameType: 'slotAndTable' })
  };

  handleDownload = payload => {
    const { downloadReport, timezone, t } = this.props

    downloadReport({ ...payload, timezone }, {
      filter: 'livePlayersOrder',
      fileName: t('orderSearch', { ns: 'sidebar' }),
      isDownloaded: this.state.isDownloaded,
    })

    if (!this.state.isDownloaded) {
      this.setState({ isDownloaded: true })
    }
  }

  handleSubmit = values => {
    const payload = {
      ...values,
      pagesize: this.state.rowsPerPage,
      startDate: formatDate(values.startDate),
      endDate: formatDate(values.endDate),
    }
    this.setState({ isDownloaded: false })

    return this.onSubmit(payload)
  };

  handleChangeTimeZone = async timezone => {
    const { payload } = this.state

    try {
      await this.onSubmit({ ...payload, timezone })
    } catch (err) {}
  };

  handleChangePage = page => {
    const { payload } = this.state

    return this.onSubmit({ ...payload, nowpage: page + 1 })
  };

  handleChangeRowsPerPage = rowsPerPage => {
    const { payload } = this.state

    this.setState({ rowsPerPage })

    return this.onSubmit({ ...payload, pagesize: rowsPerPage, nowpage: 1 })
  };

  fetchReport = payload => {
    const { fetchReport, timezone } = this.props

    return fetchReport({
      reportPayload: { ...payload, timezone },
      summaryReportPayload: { ...payload, timezone },
    }, { filter: 'livePlayersOrder' })
  };

  onSubmit = payload => {
    this.setState({ payload, isSearched: true }, () => {
      this.setFormatReports([])
      this.setColumns()
    })

    return this.fetchReport(payload)
  };

  render () {
    const {
      tableTypeOptions,
      filterAgentAccount,
      filterPlayerAccount,
      filterTableType,
      filterTableId,
      canDownload,
      isFetching,
      timezone,
      dateRange,
    } = this.props
    const {
      formatReports,
      formatSummaryReports,
      reportPagination,
      columns,
      payload,
      rowsPerPage,
      isSearched,
      tableIdOptions,
    } = this.state

    return (
      <Container>
        <GridItemFull>
          <Paper>
            <PlayerOrderForAdminForm
              roundnumber=""
              agentAcc={filterAgentAccount}
              playerAcc={filterPlayerAccount}
              tabletype={filterTableType}
              tableid={filterTableId}
              startDate={new Date(moment().utcOffset(timezone * 60).startOf('date').format('YYYY/MM/DD HH:mm:ss'))}
              endDate={new Date(moment().utcOffset(timezone * 60).endOf('date').format('YYYY/MM/DD HH:mm:ss'))}
              sortKey='bettime'
              sortValue='desc'
              pagesize={rowsPerPage}
              nowpage={1}
              // func
              isFetching={() => isFetching}
              getTableTypeOptions={() => tableTypeOptions}
              getTableIdOptions={() => tableIdOptions}
              onTableTypeChange={this.onTableTypeChange}
              onSubmit={this.handleSubmit}
              dateRange={dateRange}
            />
          </Paper>
        </GridItemFull>

        { isSearched &&
        <GridItemFull>
          <ReportSummaryCard data={formatSummaryReports} />
        </GridItemFull>
        }

        { isSearched &&
        <GridItemFull>
          <DataTable
            data={formatReports}
            columns={columns}
            pagination={reportPagination}
            payload={payload}
            canDownload={canDownload}
            downloadReport={this.handleDownload}
            options={{
              rowsPerPage,
              onChangePage: this.handleChangePage,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
            }}
          />
        </GridItemFull>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null

  const language = getLanguage(state)
  const reports = getReportByFilter(state, 'livePlayersOrder')
  const summaryReports = getReportByFilter(state, 'livePlayersOrderSummary')

  const tableTypeOptions = getLiveTableTypeOption(state)
  const tableIdAllOptions = getLiveTableIdOption(state)

  const filterAgentAccount = search.agent ? search.agent : ''
  const filterPlayerAccount = search.player ? search.player : ''
  const filterTableType = search.tableType || []
  const filterTableId = search.tableId || []

  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isFetching = getIsFetchingByFilter(state, 'livePlayersOrder') || getIsFetchingByFilter(state, 'livePlayersOrderSummary')

  // excel button 顯示及 excel 功能是否開啟
  const canDownload = reports.candownload

  // order
  const dateRange = state.order?.liveorder ? `${state.order.liveorder}day` : '1day'
  return {
    tableTypeOptions,
    tableIdAllOptions,
    canDownload,
    games: state.games,
    language,
    filterAgentAccount,
    filterPlayerAccount,
    filterTableType,
    filterTableId,
    reports,
    summaryReports,
    timezone,
    isFetching,
    dateRange,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchGameDetailLink: a.game.fetchGameDetailLink,
    downloadReport: a.report.download,
    fetchReport: a.report.fetchPlayerOrder,
    fetchTableInfo: a.live.fetchTableInfo,
  },
)(withTranslation('livePlayerOrder')(withStyles(styles)(LivePlayerOrderForAdmin)))
