const rawData = {
  // 选单功能
  bookmark: ['Bookmarks', '常用'],
  editBookmark: ['Bookmarks', '编辑常用'],
  done: ['Done', '完成'],
  closeAll: ['Collapse All', '全部收合'],
  openAll: ['Expand All', '全部展开'],
  removeBookmark: ['Unpin', '取消固定'],
  addBookmark: ['Pin', '固定'],

  // 权限管理
  permissionManagement: ['Set Permission', '权限管理'],

  // 玩家管理
  playerManagement: ['Player Management', '玩家管理'],
  playerList: ['Player List', '玩家列表'],
  orderSearch: ['Order Search', '注单查询'],
  transactionSearch: ['Transaction Search', '交易查询'],
  gameRecordDetail: ['Game Record Detail', '游戏纪录细单'],
  payoffSearch: ['Payoff Search', '派彩查询'],

  // 代理管理
  agentManagement: ['Agent Management', '代理管理'],
  agentList: ['Agent List', '代理列表'],
  createAgent: ['Create Agent', '新增代理'],
  betThresholdModification: ['Modify Bet Threshold', '更新投注阀值'],

  // 电子游戏报表
  reportCenter: ['Electronic Games', '电子游戏报表'],
  agentReport: ['Agent Report', '代理报表'],
  agentPlayerReport: ['Agent Player Report', '代理玩家报表'],
  agentPlayerGameReport: ['Agent Player Game Report', '代理玩家游戏报表'],
  agentFinanceReport: ['Agent Finance Report', '代理财务报表'],
  agentGameAnalysisReport: ['Agent Game Analysis Report', '代理游戏分析报表'],
  playerProfitReport: ['Player Profit Report', '玩家获利报表'],
  playerGameProfitReport: ['Player Game Profit Report', '玩家游戏获利报表'],
  agentlessGameReport: ['Agentless Game Report', '不分代理游戏报表'],
  playersPayoff: ['Players Payoff', '派彩清单'],

  // 体彩
  Lottery: ['Sport/Lottery', '体彩'],
  lottoAgentFinanceReport: ['Lotto Agent Finance Report', '体彩代理财务报表'],
  lottoRealTimeOrder: ['RealTime Order Search', '体彩即时注单'],
  lottoAwardedOrder: ['Awarded Order Search', '体彩开彩注单'],
  lottoAgentReport: ['Agent Report', '体彩代理报表'],
  lottoAgentPlayerReport: ['Agent Player Game Report', '体彩代理玩家游戏报表'],
  lottoAgentPlayerGameReport: [
    'Agent Player Game Item Report',
    '体彩代理玩家游戏项目报表',
  ],
  lottoAgentGameAnalysisReport: [
    'Agent Game Analysis Report',
    '体彩代理游戏分析报表',
  ],
  lottoPeriodOrder: [
    'Agent Period Order',
    '体彩期数单据查询',
  ],
  lottoAgentlessGameReport: [
    'Lotto Agentless Game Report',
    '体彩不分代理游戏报表',
  ],

  ssSumByParent: ['Agent Sum', '代理存提款总额'],
  ssSpecial: ['Agent Area', '代理专区'],
  // 客服管理
  customerServiceManagement: ['Customer Service Management', '客服管理'],
  customerServiceList: ['Customer Service List', '客服列表'],
  createCustomerService: ['Create Customer Service', '新增客服'],

  // 联名后台
  cobrandManager: ['Cobrand Manager', '联名后台'],
  setCobrand: ['Set Cobrand', '联名设定'],

  // 游戏管理
  gameManager: ['Agent Game Manager', '代理游戏管理'],
  addBlackList: ['Add Black List', '新增黑名单'],
  removeBlackList: ['Remove Black List', '移除黑名单'],
  getBlackList: ['Get Black List', '查询黑名单'],
  specialGame: ['Special Game', '特殊游戏'],

  // 真人视讯专区
  Live: ['Live Casino', '真人视讯专区'],
  liveAgentGameAnalysisReport: ['Agent Game Analysis Report', '代理游戏分析报表'],
  liveAgentPlayerReport: ['Agent Player Report', '代理玩家报表'],
  livePlayerOrder: ['Order Search', '注单查询'],
  liveAgentReport: ['Agent Report', '代理报表'],
  liveAgentFinanceReport: ['Agent Finance Report', '代理财务报表'],
  liveAgentlessGameReport: ['Agentless Game Report', '不分代理游戏报表'],
  livePlayerDonate: ['Donate Search', '打赏查询'],
  liveTwoSideBet: ['Two Side Bet Search', '对压查询'],
  liveRiskAssessment: ['Risk Membership Analysis', '风险会员分析'],
  liveSSCockFightReport: ['Cock Fight Finance Report', '斗鸡和局报表'],

  // 免费券
  freeTicketSearch: ['Free Ticket Search', '免费券查询'],
  agentPlayerFreeTicketReport: ['Agent Player Free Ticket Report', '代理玩家免费券报表'],
  freeTicketAnalysisReport: ['Free Ticket Analysis Report', '免费券分析报表'],

  // GC设定
  tableSetting: ['Table Setting', '游戏桌设定'],
  chipsSetting: ['Chips Setting', '筹码设定'],
  betLimitSetting: ['Bet Limit Setting', '限额设定'],
  donateSetting: ['Donate Setting', '打赏设定'],
  liveOverUnderUISetting: ['Over/Under UI Setting', 'Over/Under UI 设定'],

  stationLink: ['VIP Station Link', 'VIP 会员站长后台'],
  VPstationLink: ['VP Station Link', 'VP 站长后台'],
  ssRedRain: ['Red Rain', '红包雨活动'],
  ssRTP: ['RTP Setting', '调整RTP'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
