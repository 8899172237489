import React, { useCallback, useEffect } from 'react'
import { api } from '../../services'
import actions from '../../actions'
import { useDispatch, useSelector } from 'react-redux'

const StationLink = props => {
  const { location: { key: locationKey } } = props
  const dispatch = useDispatch()
  const userToken = useSelector(s => s.auth.user.usertoken)

  const fetchStationLink = useCallback(async () => {
    try {
      // const res = await api.get(
      //   api.BO,
      //   '/station/link',
      // )
      await window.open(
        `${'https://dev-gamecms.vastplay.online/login?token='}${userToken}`,
        '_blank',
      )
    } catch (err) {
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && err.data.status.message,
          variant: 'warning',
        }),
      )
    }
  }, [dispatch])
  useEffect(() => {
    fetchStationLink()
  }, [dispatch, fetchStationLink, locationKey])
  return (
    <div />
  )
}

StationLink.propTypes = {}

export default StationLink
