import React, { useCallback, useEffect } from 'react'
import { api } from '../../services'
import actions from '../../actions'
import { useDispatch } from 'react-redux'

const VPStationLink = props => {
  const { location: { key: locationKey } } = props
  const dispatch = useDispatch()

  const fetchStationLink = useCallback(async () => {
    try {
      const res = await api.get(
        api.BO,
        '/station/link',
      )
      await window.open(
        `${res.data.data}`,
        '_blank',
      )
    } catch (err) {
      dispatch(
        actions.msgs.add({
          msg: err.data && err.data.status && err.data.status.message,
          variant: 'warning',
        }),
      )
    }
  }, [dispatch])
  useEffect(() => {
    fetchStationLink()
  }, [dispatch, fetchStationLink, locationKey])
  return (
    <div />
  )
}

VPStationLink.propTypes = {}

export default VPStationLink
