import React from 'react'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'

export const TABLE_COLUMN_DATA = [
  { label: '#', align: 'center' },
  { label: 'Table ID', align: 'center' },
  { label: 'Game Type', align: 'center' },
  {
    label: 'Table Name',
    align: 'center',
    renderOption: ({ row, lang }) => {
      const convertLang = (lang) => {
        switch (lang) {
          case 'cn':
            return 'zh-cn'
          case 'tw':
            return 'zh-tw'
          default:
            return lang
        }
      }
      return !row.nameset[convertLang(lang)] ? '' : row.nameset[convertLang(lang)]
    },
  },
  {
    label: 'Switch',
    align: 'center',
    switch: true,
    renderOption: (props) => {
      const { row, disabled, handleSwitchChange, classes } = props
      const isChecked = row.viewstatus === 1
      return <Switch color="primary" className={!isChecked ? classes.disabled : classes.enabled} disabled={disabled} checked={isChecked} onChange={handleSwitchChange} />
    },
  },
  {
    label: 'TOP GAMES',
    align: 'center',
    renderOption: (props) => {
      const { row, disabled, handleCheckboxChange, classes } = props

      const isBaccarat = row?.tabletype === 'baccarat'
      const isChecked = row.istopgame
      return isBaccarat ? <Checkbox color="primary" className={!isChecked ? classes.unchecked : classes.checked} style={{ display: (disabled && !row.istopgame) && 'none' }} disabled={disabled} checked={isChecked} onChange={handleCheckboxChange} /> : ''
    },
  },
]

export const TABLE_ROW_DATA = () => {
  return {
    data: {
      ssid: '598038fa4283ac00010cabe7',
      ssaccount: 'parentjjj',
      tablesets: [
        {
          tableid: 'AAT01',
          tabletype: 'baccarat',
          viewstatus: 1,
          istopgame: false,
        },
        {
          tableid: 'AAT02',
          tabletype: 'baccarat',
          viewstatus: 0,
          istopgame: false,
        },
        {
          tableid: 'AAT03',
          tabletype: 'baccarat',
          viewstatus: 1,
          istopgame: true,
        },
      ],
    },
    status: {
      code: '0',
      message: 'Success',
      datetime: '2022-02-08T02:54:18-04:00',
      traceCode: '8dEZYjo6jS2HYh',
      latency: '227.72µs',
    },
  }
}
